import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Reasons from "../components/reasons"
import teri from "../images/Teri.jpg"
import ContactForm from "../components/contact-form"

const AboutPage = props => {
  const {
    data: { markdownRemark },
  } = props
  console.log(props)
  return (
    <Layout>
      <SEO title="Contact" />
      <main className="main-container">
        <div className="title-container">
          <h1 className="page-title">{markdownRemark.frontmatter.title}</h1>
        </div>
        <div className="main-content-container">
          <div className="contact-container">
            <div>
              <div>
                <img
                  style={{
                    maxWidth: "200px",
                    borderRadius: "50%",
                    width: "100%",
                  }}
                  src={markdownRemark.frontmatter.headshotImage}
                />
                <h2>Call</h2>
                <a
                  style={{ fontWeight: "600" }}
                  href={`tel:${markdownRemark.frontmatter.phoneNumber}`}
                >
                  {markdownRemark.frontmatter.phoneNumber}
                </a>
                <h2>Email</h2>
                <a
                  style={{ fontWeight: "600" }}
                  href={`mailto:${markdownRemark.frontmatter.emailAddress}`}
                >
                  {markdownRemark.frontmatter.emailAddress}
                </a>
              </div>
            </div>
            <div>
              <ContactForm />
            </div>
          </div>
        </div>
        <Reasons bottom />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Contact" } }) {
      frontmatter {
        title
        headshotImage
        phoneNumber
        emailAddress
      }
    }
  }
`

export default AboutPage
