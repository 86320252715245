import React from "react"

function ContactForm() {
  return (
    <form
      action="/thank-you"
      name="contact"
      data-netlify="true"
      className="contact-form"
      method="POST"
      netlify-honeypot="_honeypot"
    >
      <input type="hidden" name="_honeypot" value="" />
      <input type="hidden" name="form-name" value="contact" />
      <input required placeholder="Name" type="text" name="name" />
      <input required placeholder="Email" type="email" name="email" />
      <input placeholder="Phone" type="tel" name="phone" />
      <textarea placeholder="Comments" name="comments" />
      <input class="contact-form-submit" type="submit" value="Send" />
    </form>
  )
}

export default ContactForm
